import * as React from "react"
import { graphql } from 'gatsby';

// Plugins
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Application
import Layout from '../shell/layout'

// Styles
import { defaultPage } from '../styles/404.module.css'

// Markup
const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout pageTitle={`${t("not-found")} | `} pagePath="404">
        <div className={defaultPage}>
          <h1>{t("404-title")}</h1>
          <p>{t("404-p1")}</p>
        </div>
      </Layout>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;